import * as staticActions from "./static.actions";

export const staticFeatureKey = "staticInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    aboutUs: "",
    contactUs: ""
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case staticActions.GET_ABOUT_US_REQUEST:
            return {
                ...state,
                loading: true
            }

        case staticActions.GET_ABOUT_US_SUCCESS:
            return {
                ...state,
                loading: false,
                aboutUs: payload.data
            }

        case staticActions.GET_ABOUT_US_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                aboutUs: ""
            }

        case staticActions.GET_CONTACT_US_REQUEST:
            return {
                ...state,
                loading: true
            }

        case staticActions.GET_CONTACT_US_SUCCESS:
            return {
                ...state,
                loading: false,
                contactUs: payload.data
            }

        case staticActions.GET_CONTACT_US_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                contactUs: ""
            }

        default: return state;
    }
}