import React, { useEffect, useRef, useState } from "react";
import { FormGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { imageBaseUrl, uploadImage } from "../../../../helper";
import { toast } from "react-toastify";
import {
  couponDetails,
  updateCoupon,
} from "../../../../redux/couponCode/couponCode.action";

const CouponCodeDetails = () => {
  const [data, setData] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [logoImage, setLogoImage] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const bgImageRef = useRef(null);
  const logoRef = useRef(null);

  const { loading, details } = useSelector((state) => state.couponInfo);

  useEffect(() => {
    if (id) {
      dispatch(couponDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && Object.keys(details).length > 0) {
      setData(details);
    } else {
      setData({});
    }
  }, [details]);

  useEffect(() => {
    const isDataChanged = Object.keys(details).some(
      (key) => data[key] !== details[key]
    );
    if (isDataChanged) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }

    if (backgroundImage) {
      setIsUpdate(true);
    }

    if (logoImage) {
      setIsUpdate(true);
    }
  }, [data, backgroundImage, logoImage]);

  const handleBgImageButtonClick = () => {
    bgImageRef.current.click();
  };

  const handleLogoButtonClick = () => {
    logoRef.current.click();
  };

  const handleUpdateCoupon = async () => {
    if (!data.title || !data.category || !data.description) {
      toast("Please fill fields");
      return;
    }
    let image = "";

    if (backgroundImage?.file) {
      image = await uploadImage(
        "/coupon/upload/coupon/image/v1",
        backgroundImage.file,
        setBackgroundImage
      );
    } else {
      image = data?.image;
    }

    dispatch(updateCoupon(id, { ...data, image }));
  };

  return (
    <>
      {loading ? (
        <div className="w-100 d-flex">
          <div
            className="spinner-border text-primary mx-auto"
            role="status"
          ></div>
        </div>
      ) : (
        <>
          {data && Object.keys(data).length > 0 ? (
            <div>
              <div className="page-header">
                <h3 className="page-title">Coupon Detail</h3>
              </div>
              <div className="container-fluid">
                {
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6">
                          {backgroundImage?.file ? (
                            <>
                              <label>Backgroud image</label>
                              <br />
                              <img
                                src={URL.createObjectURL(backgroundImage.file)}
                                style={{ width: "200px", height: "200px" }}
                              />
                            </>
                          ) : (
                            <>
                              {data?.image && (
                                <>
                                  <label>Coupon Code Image</label>
                                  <br />
                                  <img
                                    src={`${imageBaseUrl}${data?.image}`}
                                    style={{ width: "200px", height: "200px" }}
                                  />
                                </>
                              )}
                            </>
                          )}
                          <button
                            className="btn_primary_outline_sm mb-3 mt-3"
                            onClick={handleBgImageButtonClick}
                          >
                            Change image
                          </button>
                          <input
                            type="file"
                            ref={bgImageRef}
                            accept="image/*"
                            onChange={(e) =>
                              setBackgroundImage({ file: e.target.files[0] })
                            }
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <FormGroup className="mb-3">
                            <label>Title</label>
                            <input
                              type="text"
                              placeholder="Enter Title"
                              value={data.title}
                              className="form-control"
                              onChange={(e) =>
                                setData({ ...data, title: e.target.value })
                              }
                            />
                          </FormGroup>
                        </div>
                        <div className="col-6">
                          <FormGroup className="mb-3">
                            <label>Description</label>
                            <textarea
                              className="form-control"
                              placeholder="Description"
                              value={data.description}
                              rows="4"
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  description: e.target.value,
                                })
                              }
                            ></textarea>
                          </FormGroup>
                        </div>
                        <div className="col-6">
                          <FormGroup className="mb-3">
                            <label>Category</label>
                            <select
                              type="text"
                              placeholder="Category"
                              value={data.category}
                              className="form-control"
                              onChange={(e) =>
                                setData({ ...data, category: e.target.value })
                              }
                            >
                              <option selected value="">
                                Select Category
                              </option>
                              <option value="Order">Order</option>
                              <option value="Homecare">Homecare</option>
                              <option value="Consultation">Consultation</option>
                            </select>
                          </FormGroup>
                        </div>
                        <div className="col-6">
                          <FormGroup className="mb-3">
                            <label>Discount %</label>
                            <input
                              type="number"
                              placeholder="Discount"
                              value={data.discount}
                              className="form-control"
                              onChange={(e) =>
                                setData({ ...data, discount: e.target.value })
                              }
                            />
                          </FormGroup>
                        </div>
                        <div className="col-6">
                          <FormGroup className="mb-3">
                            <label>Required Min Price</label>
                            <input
                              type="number"
                              placeholder="Enter Required Min Price"
                              value={data.requiredMinPrice}
                              className="form-control"
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  requiredMinPrice: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </div>
                        <div className="col-6">
                          <FormGroup className="mb-3">
                            <label>Upto Price</label>
                            <input
                              type="number"
                              placeholder="Enter Upto Price"
                              value={data.uptoPrice}
                              className="form-control"
                              onChange={(e) =>
                                setData({ ...data, uptoPrice: e.target.value })
                              }
                            />
                          </FormGroup>
                        </div>
                      </div>
                      {isUpdate && (
                        <button
                          className="btn_primary_outline_sm"
                          disabled={loading}
                          onClick={(e) => handleUpdateCoupon()}
                        >
                          {loading ? (
                            <div className="spinner-border" role="status">
                              <span className="sr-only"></span>
                            </div>
                          ) : (
                            "UPDATE"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                }
              </div>
            </div>
          ) : (
            <h2 className="text-center mt-3 text-muted">No Data Found</h2>
          )}
        </>
      )}
    </>
  );
};

export default CouponCodeDetails;
