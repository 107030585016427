import React from "react";
import { Modal, ModalBody } from "react-bootstrap";

const DeleteModal = ({ deleteModal, setDeleteModal, handlePerform }) => {
  return (
    <Modal
      show={deleteModal}
      size="sx"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <h5 className="mb-3">Delete Category ?</h5>
      </ModalBody>
      <Modal.Footer>
        <button
          type="button"
          className="btn_secondary"
          onClick={() => setDeleteModal(false)}
        >
          Cancel
        </button>
        <button type="button" className="btn_primary" onClick={e => handlePerform()}>
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
