import React, { useEffect, useRef, useState } from "react";
import { FormGroup } from "react-bootstrap";
import * as bannerActions from "../../../../redux/banners/banner.actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { imageBaseUrl, uploadImage } from "../../../../helper";
import { toast } from "react-toastify";

const BannerDetail = () => {

  const [data, setData] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [logoImage, setLogoImage] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const bgImageRef = useRef(null);
  const logoRef = useRef(null);

  const loading = useSelector(state => state.bannerInfo.loading);
  const details = useSelector(state => state.bannerInfo.details);

  useEffect(() => {
    if (id) {
      dispatch(bannerActions.bannerDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && Object.keys(details).length > 0) {
      setData(details);
    } else {
      setData({});
    }
  }, [details]);

  useEffect(() => {
    const isDataChanged = Object.keys(details).some(key => data[key] !== details[key]);
    if (isDataChanged) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }

    if (backgroundImage) {
      setIsUpdate(true);
    }

    if (logoImage) {
      setIsUpdate(true);
    }
  }, [data, backgroundImage, logoImage]);

  const handleBgImageButtonClick = () => {
    bgImageRef.current.click();
  };

  const handleLogoButtonClick = () => {
    logoRef.current.click();
  };

  const handleUpdateBanner = async () => {
    if (!data.app || !data.category) {
      toast("Please fill fields");
      return;
    }
    let bgImage = "";
    // let bannerLogoImage = "";

    if (backgroundImage?.file) {
      bgImage = await uploadImage('/admin/upload/banner/bg/image/v1', backgroundImage.file, setBackgroundImage);
    } else {
      bgImage = data?.bgImage;
    }

    // if (logoImage?.file) {
    //   bannerLogoImage = await uploadImage('/admin/upload/banner/bg/image/v1', logoImage.file, setLogoImage);
    // } else {
    //   bannerLogoImage = data?.bannerLogoImage;
    // }

    // dispatch(bannerActions.updateBanner(id, { ...data, bgImage, bannerLogoImage }));
    dispatch(bannerActions.updateBanner(id, { ...data, bgImage }));
  };

  return (
    <>
      {
        loading ?
          <div className="w-100 d-flex">
            <div
              className="spinner-border text-primary mx-auto"
              role="status"
            ></div>
          </div> :
          <>
            {
              data && Object.keys(data).length > 0 ?
                <div>
                  <div className="page-header">
                    <h3 className="page-title">Banner Detail</h3>
                  </div>
                  <div className="container-fluid">
                    {
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-6">
                              {
                                backgroundImage?.file ?
                                  <>
                                    <label>Backgroud image</label>
                                    <br />
                                    <img src={URL.createObjectURL(backgroundImage.file)} style={{ width: "200px", height: "200px" }} />
                                  </> :
                                  <>
                                    {
                                      data?.bgImage &&
                                      <>
                                        <label>Backgroud image</label>
                                        <br />
                                        <img src={`${imageBaseUrl}${data?.bgImage}`} style={{ width: "200px", height: "200px" }} />
                                      </>
                                    }
                                  </>
                              }
                              <button className="btn_primary_outline_sm mb-3 mt-3" onClick={handleBgImageButtonClick}>Change image</button>
                              <input
                                type="file"
                                ref={bgImageRef}
                                accept="image/*"
                                onChange={e => setBackgroundImage({ file: e.target.files[0] })}
                                style={{ display: 'none' }}
                              />
                            </div>
                            <div className="col-6">
                              {
                                logoImage?.file ?
                                  <>
                                    <label>Banner logo</label>
                                    <br />
                                    <img src={URL.createObjectURL(logoImage.file)} style={{ width: "200px", height: "200px" }} />
                                  </> :
                                  <>
                                    {/* {
                                      data?.bannerLogoImage &&
                                      <>
                                        <label>Banner logo</label>
                                        <br />
                                        <img src={`${imageBaseUrl}${data?.bannerLogoImage}`} style={{ width: "200px", height: "200px" }} />
                                      </>
                                    } */}
                                  </>
                              }
                              {/* <button className="btn_primary_outline_sm mb-3 mt-3" onClick={handleLogoButtonClick}>Change logo</button>
                              <input
                                type="file"
                                ref={logoRef}
                                accept="image/*"
                                onChange={e => setLogoImage({ file: e.target.files[0] })}
                                style={{ display: 'none' }}
                              /> */}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <FormGroup className="mb-3">
                                <label>Button name</label>
                                <input
                                  type="text"
                                  placeholder="Button name"
                                  value={data.buttonName}
                                  className="form-control"
                                  onChange={(e) =>
                                    setData({ ...data, buttonName: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </div>
                            <div className="col-6">
                              <FormGroup className="mb-3">
                                <label>App</label>
                                <select
                                  type="text"
                                  placeholder="App"
                                  value={data.app}
                                  className="form-control"
                                  onChange={e => setData({ ...data, app: e.target.value })}
                                >
                                  <option selected value="">
                                    Select App
                                  </option>
                                  <option value="user">user</option>
                                  <option value="vet">vet</option>
                                  <option value="vendor">vendor</option>
                                </select>
                              </FormGroup>
                            </div>
                            <div className="col-6">
                              <FormGroup className="mb-3">
                                <label>Category</label>
                                <select
                                  type="text"
                                  placeholder="Category"
                                  value={data.category}
                                  className="form-control"
                                  onChange={e => setData({ ...data, category: e.target.value })}
                                >
                                  <option selected value="">
                                    Select Category
                                  </option>
                                  <option value="ecom">ecom</option>
                                  <option value="home">home</option>
                                </select>
                              </FormGroup>
                            </div>
                            <div className="col-6">
                              <FormGroup className="mb-3">
                                <label>Heading</label>
                                <input
                                  type="text"
                                  placeholder="Heading"
                                  value={data.headName}
                                  className="form-control"
                                  onChange={(e) =>
                                    setData({ ...data, headName: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </div>
                            <div className="col-6">
                              <FormGroup className="mb-3">
                                <label>Description</label>
                                <textarea
                                  className="form-control"
                                  placeholder="Description"
                                  value={data.paragraph}
                                  rows="4"
                                  onChange={(e) =>
                                    setData({ ...data, paragraph: e.target.value })
                                  }
                                ></textarea>
                              </FormGroup>
                            </div>
                          </div>
                          {
                            isUpdate &&
                            <button className="btn_primary_outline_sm" disabled={loading} onClick={e => handleUpdateBanner()}>
                              {
                                loading ?
                                  <div className="spinner-border" role="status">
                                    <span className="sr-only"></span>
                                  </div> :
                                  "UPDATE"
                              }
                            </button>
                          }
                        </div>
                      </div>
                    }
                  </div>
                </div> :
                <h2 className="text-center mt-3 text-muted">No Data Found</h2>
            }
          </>
      }
    </>
  );
};

export default BannerDetail;
