import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, ModalBody, Row } from "react-bootstrap";
import ViewMoreBtn from "../../components/ViewMoreBtn";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { arrowRight } from "../../../../assets/svg/Svg";
import { LuFilter } from "react-icons/lu";
import { FiEdit } from "react-icons/fi";
import * as notificationActions from "../../../../redux/pushNotification/notification.actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import { imageBaseUrl } from "../../../../helper";

const PushNotification = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    image: "",
    title: "",
  });
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [notificationId, setNotificationId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const dispatch = useDispatch();

  const loading = useSelector(state => state.usersNotificationInfo.loading);
  const list = useSelector(state => state.usersNotificationInfo.list);
  const isStatusChange = useSelector(state => state.usersNotificationInfo.isStatusChange);
  const prevProps = useRef({ isStatusChange }).current;

  useEffect(() => {
    dispatch(notificationActions.notificationList());
  }, []);

  useEffect(() => {
    if (list && list.length > 0) {
      setData(list);
    } else {
      setData([]);
    }
  }, [list]);

  useEffect(() => {
    if (prevProps.isStatusChange !== isStatusChange) {
      if (isStatusChange) {
        dispatch(notificationActions.notificationList());
      }
    }

    return () => {
      prevProps.isStatusChange = isStatusChange;
    }
  }, [isStatusChange]);

  const manageReSendNotification = (id) => {
    setNotificationId(id);
    setModalShow(true);
  };

  const columns = [
    {
      name: "Image",
      selector: (row) => row?.image ? <img className="list_img" src={`${imageBaseUrl}${row.image}`} /> : '-',
      sortable: true,
    },
    {
      name: "Notification Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Content",
      selector: (row) => row.body,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => moment(row?.createdAt).format("DD-MM-YYYY h:mm a"),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <button
          className="btn_primary_outline_sm ms-auto"
          onClick={e => manageReSendNotification(row._id)}
        >
          RE-SEND
        </button>
      ),
      sortable: true,
    }
  ];

  // const data = [
  //   {
  //     id: 0,
  //     pnImage: <img className="list_img" src="https://picsum.photos/200" />,
  //     pnTitle: "Dog",
  //     pnContent: "01-01-2024",
  //   },
  //   {
  //     id: 1,
  //     pnImage: <img className="list_img" src="https://picsum.photos/200" />,
  //     pnTitle: "Dog",
  //     pnContent: "01-01-2024",
  //   },
  //   {
  //     id: 2,
  //     pnImage: <img className="list_img" src="https://picsum.photos/200" />,
  //     pnTitle: "Dog",
  //     pnContent: "01-01-2024",
  //   },
  //   {
  //     id: 3,
  //     pnImage: <img className="list_img" src="https://picsum.photos/200" />,
  //     pnTitle: "Dog",
  //     pnContent: "01-01-2024",
  //   },
  //   {
  //     id: 4,
  //     pnImage: <img className="list_img" src="https://picsum.photos/200" />,
  //     pnTitle: "Dog",
  //     pnContent: "01-01-2024",
  //   },
  // ];

  const filteredData = data.filter(
    (item) =>
      item.title.toLowerCase().includes(searchText.toLowerCase()) ||
      item.body.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleSearch = (value) => {
    setSearchText(value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleStatusChange = (value) => {
    setStatus(value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: "All",
  };

  const customPagination = () => {
    const page = Math.ceil(filteredData.length / paginationPerPage);
    const pageButtons = [];
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, page);

    // Always add the first page
    pageButtons.push(
      <button
        key={1}
        className={`btn-pagination ${currentPage === 1 ? "active" : ""}`}
        onClick={() => setCurrentPage(1)}
      >
        1
      </button>
    );

    // Ellipsis after the first page if necessary
    if (startPage > 2) {
      pageButtons.push(
        <span key="ellipsis-start" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Range of buttons around the current page
    for (let i = startPage; i <= endPage; i++) {
      if (i !== 1 && i !== page) {
        pageButtons.push(
          <button
            key={i}
            className={`btn-pagination ${currentPage === i ? "active" : ""}`}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </button>
        );
      }
    }

    // Ellipsis before the last page if necessary
    if (endPage < page - 1) {
      pageButtons.push(
        <span key="ellipsis-end" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Always add the last page
    if (page !== 1) {
      pageButtons.push(
        <button
          key={page}
          className={`btn-pagination ${currentPage === page ? "active" : ""}`}
          onClick={() => setCurrentPage(page)}
        >
          {page}
        </button>
      );
    }

    return (
      <div className="pagination-container mt-5">
        <button
          className="btn-pagination btn-pagi_prev"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {arrowRight}
        </button>
        <span>{pageButtons}</span>
        <button
          className="btn-pagination btn-pagi_next"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === page}
        >
          {arrowRight}
        </button>
      </div>
    );
  };

  const paginationPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(1);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * paginationPerPage,
    currentPage * paginationPerPage
  );

  const handleReSendNotification = (id) => {
    dispatch(notificationActions.reSendNotification(id, setNotificationId, setModalShow));
  };

  return (
    <>
      {loading ? (
        <div className="w-100 d-flex">
          <div
            className="spinner-border text-primary mx-auto"
            role="status"
          ></div>
        </div>
      ) : (
        <>
          <div>
            <div className="page-header">
              <h3 className="page-title">Push Notification</h3>
            </div>
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body py-4">
                    <>
                      {/* <button onClick={handleClearRows}>Clear Selected Rows</button> */}
                      <DataTable
                        // title={<h6 className="card-title">Users List</h6>}
                        columns={columns}
                        data={paginatedData}
                        pagination
                        paginationServer
                        paginationTotalRows={filteredData.length}
                        onChangePage={(page) => setCurrentPage(page)}
                        paginationComponentOptions={paginationOptions}
                        paginationComponent={customPagination}
                        highlightOnHover
                        pointerOnHover
                        subHeader
                        subHeaderAlign="left"
                        subHeaderComponent={
                          <>
                            <Row className="mb-3 w-100">
                              <Col
                                md={8}
                                className="d-flex align-items-start flex-column gap-2"
                              >
                                <span className="d-flex align-items-center gap-2 me-3 filterby_label">
                                  <LuFilter />
                                  Filter By :
                                </span>
                                <div className="table_filter">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by Title or Content"
                                    value={searchText}
                                    onChange={(e) =>
                                      handleSearch(e.target.value)
                                    }
                                  />
                                </div>
                              </Col>
                              <Col md={4} className="d-flex align-items-end">
                                <button
                                  className="btn_primary_outline_sm ms-auto"
                                  onClick={() =>
                                    navigate("/push-notification/send")
                                  }
                                >
                                  Send New Notification
                                </button>
                              </Col>
                            </Row>
                          </>
                        }
                      />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Modal
        show={modalShow}
        size="sx"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalBody>
          <h5 className="mb-3">Are you sure?</h5>
        </ModalBody>
        <Modal.Footer>
          <button
            type="button"
            className="btn_secondary"
            onClick={() => {
              setModalShow(false);
              setNotificationId(null);
            }}
          >
            CANCEL
          </button>
          <button type="button" className="btn_primary" disabled={loading} onClick={e => handleReSendNotification(notificationId)}>
            {
              loading ?
                <>
                  <div
                    className="spinner-border text-primary mx-auto"
                    role="status"
                  ></div>
                </>
                :
                "SEND"
            }
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PushNotification;
