import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, ModalBody, Card, Row, Col, Dropdown } from "react-bootstrap";
import { RxAvatar } from "react-icons/rx";
import { MdOutlineLogout } from "react-icons/md";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { imageBaseUrl } from "../../../helper";
import { close, search } from "../../../assets/svg/Svg";
import { searchResult } from "../../../redux/user/user.actions";
import * as userActions from "../../../redux/user/user.actions";
import { MdLocalOffer, MdArticle, MdPerson, MdPets, MdShoppingCart, MdVideoLibrary, MdLocalHospital, MdDirectionsCar, MdPayment } from "react-icons/md";

const Navbar = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchResult, setSearchResult] = React.useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const details = useSelector((state) => state.authInfo.profile);
  const searchData = useSelector((state) => state.userInfo.searchResult);

  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setModalShow(false);
    navigate("/login");
    toast.success("Logout Successfully");
  };

  // const toggleRightSidebar = () => {
  //   document.querySelector(".right-sidebar").classList.toggle("open");
  // };

  const targetRef = useRef(null);
  const [profiledropdown, setProfiledropdown] = React.useState(false);
  const handleButtonClick = (event) => {
    event.stopPropagation(); // Stop event propagation
    setProfiledropdown(!profiledropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (targetRef.current && !targetRef.current.contains(event.target)) {
        setProfiledropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(userActions.searchResult(searchResult));
  }, [searchResult]);

  return (
    <>
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/">
            <img src={require("../../../assets/images/logo.png")} alt="logo" />
          </Link>
          <Link className="navbar-brand brand-logo-mini" to="/">
            <img
              src={require("../../../assets/images/logo-mini.png")}
              alt="logo"
            />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <FeatherIcon className="cursor-pointer" icon="menu" size="18" />
          </button>

          {/* <CustomModal
            confirmOnClick={handleLogout}
            btnClass="mdi-logout mx-2 ms-auto text-primary ml-auto align-self-center navbar-logout-btn"
            modalBodyContent="Are you sure want to Logout!"
          /> */}
          <div className="navbar_location_search m-auto">
            <Dropdown show={showDropdown} onToggle={() => setShowDropdown(!showDropdown)}>
              <Dropdown.Toggle
                className="navbar_search_box mx-0 p-0"
                id="dropdown-basic"
              >
                <input
                  type="text"
                  placeholder="Search here"
                  value={searchResult}
                  onChange={(e) => {
                    const filteredValue = e.target.value.replace(/[^a-z]/g, "");
                    setSearchResult(filteredValue);
                    setShowDropdown(true);
                  }}
                />
                {search}
              </Dropdown.Toggle>

              {/* <Dropdown.Menu className="navbar_search_box_dropdown py-3"> */}
              <Dropdown.Menu className="w-100 p-3" style={{ height: "80vh", overflow: "scroll" }}>
                <p>Recent Searches</p>
                <Row onClick={e => setShowDropdown(false)}>
                  {searchData?.products?.length > 0 && (
                    <Col md={6}>
                      <h6><MdLocalOffer /> Products</h6>
                      {searchData?.products?.map((p) => (
                        <Card key={p?._id} className="mb-2" onClick={() => navigate(`/product/${p?._id}`)}>
                          <Card.Body>
                            <Card.Title>{p?.name}</Card.Title>
                          </Card.Body>
                        </Card>
                      ))}
                    </Col>
                  )}
                  {searchData?.blogs?.length > 0 && (
                    <Col md={6}>
                      <h6><MdArticle /> Blogs</h6>
                      {searchData?.blogs?.map((p) => (
                        <Card key={p?._id} className="mb-2" onClick={() => navigate(`/blog/${p?._id}`)}>
                          <Card.Body>
                            <Card.Title>{p?.title}</Card.Title>
                          </Card.Body>
                        </Card>
                      ))}
                    </Col>
                  )}
                  {searchData?.users?.length > 0 && (
                    <Col md={6}>
                      <h6><MdPerson /> Users</h6>
                      {searchData?.users?.map((p) => (
                        <Card key={p?._id} className="mb-2" onClick={() => navigate(`/user/${p?._id}`)}>
                          <Card.Body>
                            <Card.Title>{p?.name}</Card.Title>
                          </Card.Body>
                        </Card>
                      ))}
                    </Col>
                  )}
                  {searchData?.vets?.length > 0 && (
                    <Col md={6}>
                      <h6><MdLocalHospital /> Vets</h6>
                      {searchData?.vets?.map((p) => (
                        <Card key={p?._id} className="mb-2" onClick={() => navigate(`/vet/${p?.vet?._id}`)}>
                          <Card.Body>
                            <Card.Title>{p?.vet_personal_info?.name}</Card.Title>
                          </Card.Body>
                        </Card>
                      ))}
                    </Col>
                  )}
                  {searchData?.videos?.length > 0 && (
                    <Col md={6}>
                      <h6><MdVideoLibrary /> Videos</h6>
                      {searchData?.videos?.map((p) => (
                        <Card key={p?._id} className="mb-2" onClick={() => navigate(`/video/${p?._id}`)}>
                          <Card.Body>
                            <Card.Title>{p?.title}</Card.Title>
                          </Card.Body>
                        </Card>
                      ))}
                    </Col>
                  )}
                  {searchData?.ambulances?.length > 0 && (
                    <Col md={6}>
                      <h6><MdDirectionsCar /> Ambulances</h6>
                      {searchData?.ambulances?.map((a) => (
                        <Card key={a?.id} className="mb-2">
                          <Card.Body>
                            <Card.Title>{a?.name}</Card.Title>
                          </Card.Body>
                        </Card>
                      ))}
                    </Col>
                  )}
                  {searchData?.couponCodes?.length > 0 && (
                    <Col md={6}>
                      <h6><MdLocalOffer /> Coupons</h6>
                      {searchData?.couponCodes?.map((p) => (
                        <Card key={p?._id} className="mb-2" onClick={() => navigate(`/coupon-codes/${p?._id}`)}>
                          <Card.Body>
                            <Card.Title>{p?.title}</Card.Title>
                          </Card.Body>
                        </Card>
                      ))}
                    </Col>
                  )}
                  {searchData?.govPets?.length > 0 && (
                    <Col md={6}>
                      <h6><MdPets /> Government Pets</h6>
                      {searchData?.govPets?.map((p) => (
                        <Card key={p?._id} className="mb-2" onClick={() => navigate(`/government-pet/${p?._id}`)}>
                          <Card.Body>
                            <Card.Title>{p?.petName}</Card.Title>
                          </Card.Body>
                        </Card>
                      ))}
                    </Col>
                  )}
                  {searchData?.orders?.length > 0 && (
                    <Col md={6}>
                      <h6><MdShoppingCart /> Orders</h6>
                      {searchData?.orders?.map((o) => (
                        <Card key={o?._id} className="mb-2" onClick={() => navigate(`/order/${o?._id}`)}>
                          <Card.Body>
                            <Card.Title>Order ID: {o?.orderId}</Card.Title>
                          </Card.Body>
                        </Card>
                      ))}
                    </Col>
                  )}
                  {searchData?.payments?.length > 0 && (
                    <Col md={6}>
                      <h6><MdPayment /> Payments</h6>
                      {searchData?.payments?.map((p) => (
                        <Card key={p?._id} className="mb-2" onClick={() => navigate(`/payment/${p?._id}`)}>
                          <Card.Body>
                            <Card.Title>Transaction ID: {p?.transaction_id}</Card.Title>
                          </Card.Body>
                        </Card>
                      ))}
                    </Col>
                  )}
                  {searchData?.pets?.length > 0 && (
                    <Col md={6}>
                      <h6><MdPets /> Pets</h6>
                      {searchData?.pets?.map((p) => (
                        <Card key={p?._id} className="mb-2" onClick={() => navigate(`/pet/${p?._id}`)}>
                          <Card.Body>
                            <Card.Title>{p?.petName}</Card.Title>
                          </Card.Body>
                        </Card>
                      ))}
                    </Col>
                  )}
                </Row>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="navbar_profile my-auto ms-auto">
            <span onClick={handleButtonClick} className="navbar_profile_btn">
              {details?.image ? (
                <img
                  src={`${imageBaseUrl}${details.image}`}
                  style={{ width: "35px" }}
                  className="rounded-circle"
                />
              ) : (
                <RxAvatar size={25} />
              )}{" "}
              {details?.name ? details.name : "Admin"}
            </span>
            {profiledropdown ? (
              <div className="navbar_profile_dropdown" ref={targetRef}>
                <button onClick={() => navigate("/profile")}>
                  Profile <RxAvatar size={18} />
                </button>
                <button onClick={() => setModalShow(true)}>
                  Logout <MdOutlineLogout size={18} />
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={toggleOffcanvas}
          >
            <FeatherIcon className="cursor-pointer" icon="menu" size="18" />
          </button>
        </div>
      </nav>
      <Modal
        show={modalShow}
        size="sx"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalBody>
          <h4>Are you sure want to logout!</h4>
        </ModalBody>
        <Modal.Footer>
          <button
            type="button"
            className="btn_secondary"
            onClick={() => setModalShow(false)}
          >
            Not now
          </button>
          <button onClick={handleLogout} type="button" className="btn_primary">
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Navbar;
