import React, { useEffect, useState } from "react";
import ViewMoreBtn from "../../components/ViewMoreBtn";
import DataTable from "react-data-table-component";
import { arrowRight } from "../../../../assets/svg/Svg";
import { FiEdit } from "react-icons/fi";
import { LuFilter } from "react-icons/lu";
import { Col, Row } from "react-bootstrap";

const Mail = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [category, setCategory] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const columns = [
        {
            name: "Type",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Actions",
            selector: (row) => (
                <div className="d-flex ">
                    <ViewMoreBtn redirectUrl={`/mail/${row.name.toLowerCase()}`} />
                </div>
            ),
        },
    ];

    const data = [
        {
            id: 0,
            name: "Admin",
        },
        {
            id: 1,
            name: "Vet",
        },
        {
            id: 2,
            name: "Vendor",
        },
        {
            id: 3,
            name: "User",
        },
    ];

    const filteredData = data.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()) && (category ? item.status.props.children === category : true));

    const handleSearch = (value) => {
        setSearchText(value);
        setResetPaginationToggle(!resetPaginationToggle);
    };

    const handleCategoryChange = (value) => {
        setCategory(value);
        setResetPaginationToggle(!resetPaginationToggle);
    };

    const paginationOptions = {
        rowsPerPageText: "Rows per page:",
        rangeSeparatorText: "of",
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: "All",
    };

    const customPagination = () => {
        const page = Math.ceil(filteredData.length / paginationPerPage);
        const pageButtons = [];
        const startPage = Math.max(currentPage - 1, 1);
        const endPage = Math.min(currentPage + 1, page);

        // Always add the first page
        pageButtons.push(
            <button key={1} className={`btn-pagination ${currentPage === 1 ? "active" : ""}`} onClick={() => setCurrentPage(1)}>
                1
            </button>
        );

        // Ellipsis after the first page if necessary
        if (startPage > 2) {
            pageButtons.push(
                <span key="ellipsis-start" className="pagination-ellipsis">
                    &hellip;
                </span>
            );
        }

        // Range of buttons around the current page
        for (let i = startPage; i <= endPage; i++) {
            if (i !== 1 && i !== page) {
                pageButtons.push(
                    <button key={i} className={`btn-pagination ${currentPage === i ? "active" : ""}`} onClick={() => setCurrentPage(i)}>
                        {i}
                    </button>
                );
            }
        }

        // Ellipsis before the last page if necessary
        if (endPage < page - 1) {
            pageButtons.push(
                <span key="ellipsis-end" className="pagination-ellipsis">
                    &hellip;
                </span>
            );
        }

        // Always add the last page
        if (page !== 1) {
            pageButtons.push(
                <button key={page} className={`btn-pagination ${currentPage === page ? "active" : ""}`} onClick={() => setCurrentPage(page)}>
                    {page}
                </button>
            );
        }

        return (
            <div className="pagination-container mt-5">
                <button className="btn-pagination btn-pagi_prev" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                    {arrowRight}
                </button>
                <span>{pageButtons}</span>
                <button className="btn-pagination btn-pagi_next" onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === page}>
                    {arrowRight}
                </button>
            </div>
        );
    };

    const paginationPerPage = 10;
    const [currentPage, setCurrentPage] = React.useState(1);

    const paginatedData = filteredData.slice((currentPage - 1) * paginationPerPage, currentPage * paginationPerPage);

    return (
        <>
            {isLoading ? (
                <div className="w-100 d-flex">
                    <div className="spinner-border text-primary mx-auto" role="status"></div>
                </div>
            ) : (
                <>
                    <div>
                        <div className="page-header">
                            <h3 className="page-title">Mail</h3>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body py-4">
                                        <>
                                            {/* <button onClick={handleClearRows}>Clear Selected Rows</button> */}
                                            <DataTable
                                                // title={<h6 className="card-title">Users List</h6>}
                                                columns={columns}
                                                data={paginatedData}
                                                pagination
                                                paginationServer
                                                paginationTotalRows={filteredData.length}
                                                onChangePage={(page) => setCurrentPage(page)}
                                                paginationComponentOptions={paginationOptions}
                                                paginationComponent={customPagination}
                                                highlightOnHover
                                                pointerOnHover
                                                subHeader
                                                subHeaderAlign="left"
                                                subHeaderComponent={
                                                    <>
                                                        <Row className="mb-3 w-100">
                                                            <Col md={12} className="d-flex gap-2">
                                                                <div className="d-flex align-items-start flex-column justify-content-end gap-2">
                                                                    <span className="d-flex align-items-center gap-2 me-3 filterby_label">
                                                                        <LuFilter />
                                                                        Filter By :
                                                                    </span>
                                                                    <div className="table_filter">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Search by name"
                                                                            value={searchText}
                                                                            onChange={(e) => {
                                                                                setCurrentPage(1);
                                                                                handleSearch(e.target.value);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-items-start flex-column justify-content-end gap-2">
                                                                    <div className="table_filter">
                                                                        <select className="form-control h-54px w-auto" value={category} onChange={(e) => handleCategoryChange(e.target.value)}>
                                                                            <option selected value="">
                                                                                Status
                                                                            </option>
                                                                            <option value="Active">Active</option>
                                                                            <option value="Inactive">Inactive</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-items-start flex-column justify-content-end ms-auto gap-2">
                                                                    <button className="btn_primary_outline_sm ms-auto">Export CSV</button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                }
                                            />
                                        </>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Mail;
