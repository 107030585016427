import React, { useState } from "react";
import { Col, Input, FormGroup, Modal, ModalBody, Row } from "react-bootstrap";
import tick from "../../../../assets/svg/tick.svg";

const SubAdminCreate = () => {
  const [formData, setFormData] = useState({
    image: "",
    title: "",
  });
  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">Create New Sub-Admin</h3>
      </div>
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              {/* <h5 className="card-title">Enter new product details</h4> */}
              <Row>
                <Col md={5} className="ps-5 pe-4">
                  <Row>
                    <Col md={12}>
                      <FormGroup className="mb-3">
                        <label>First Name</label>
                        <input
                          type="text"
                          placeholder="Enter Firstname"
                          value={formData.title}
                          className="form-control"
                          onChange={(e) =>
                            setFormData({ ...formData, title: e.target.value })
                          }
                          required={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="mb-3">
                        <label>Last Name</label>
                        <input
                          type="text"
                          placeholder="Enter Lastname"
                          value={formData.title}
                          className="form-control"
                          onChange={(e) =>
                            setFormData({ ...formData, title: e.target.value })
                          }
                          required={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="mb-3">
                        <label>Email</label>
                        <input
                          type="text"
                          placeholder="Enter Email"
                          value={formData.title}
                          className="form-control"
                          onChange={(e) =>
                            setFormData({ ...formData, title: e.target.value })
                          }
                          required={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="mb-3">
                        <label>Password</label>
                        <input
                          type="text"
                          placeholder="Enter Password"
                          value={formData.title}
                          className="form-control"
                          onChange={(e) =>
                            setFormData({ ...formData, title: e.target.value })
                          }
                          required={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="mb-3">
                        <label>Password</label>
                        <input
                          type="text"
                          placeholder="Enter Confirm Password"
                          value={formData.title}
                          className="form-control"
                          onChange={(e) =>
                            setFormData({ ...formData, title: e.target.value })
                          }
                          required={true}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col md={7} className="ps-4 pe-5">
                  <Row>
                    <Col md={12}>
                      <div class="mb-3 modal_input">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label text-center w-100"
                        >
                          Select to give Permissions
                        </label>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div class="mb-3 d-flex justify-content-between align-items-center">
                        <p className="select_permission_label">Users</p>
                        <div className="d-flex align-items-center gap-3 select_permission_group">
                          <div className="select_permission">
                            <label htmlFor="remember1" className="selectCheck">
                              <input type="checkbox" id="remember1" />
                              <span className="tick">
                                <img src={tick} alt="" />
                              </span>
                              <span>Read</span>
                            </label>
                          </div>
                          <div className="select_permission">
                            <label htmlFor="remember2" className="selectCheck">
                              <input type="checkbox" id="remember2" />
                              <span className="tick">
                                <img src={tick} alt="" />
                              </span>
                              <span>Create</span>
                            </label>
                          </div>
                          <div className="select_permission">
                            <label htmlFor="remember3" className="selectCheck">
                              <input type="checkbox" id="remember3" />
                              <span className="tick">
                                <img src={tick} alt="" />
                              </span>
                              <span>Edit</span>
                            </label>
                          </div>
                          <div className="select_permission">
                            <label htmlFor="remember4" className="selectCheck">
                              <input type="checkbox" id="remember4" />
                              <span className="tick">
                                <img src={tick} alt="" />
                              </span>
                              <span>Delete</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3 d-flex justify-content-between align-items-center">
                        <p className="select_permission_label">Location</p>
                        <div className="d-flex align-items-center gap-3 select_permission_group">
                          <div className="select_permission">
                            <label htmlFor="remember1" className="selectCheck">
                              <input type="checkbox" id="remember1" />
                              <span className="tick">
                                <img src={tick} alt="" />
                              </span>
                              <span>Read</span>
                            </label>
                          </div>
                          <div className="select_permission">
                            <label htmlFor="remember2" className="selectCheck">
                              <input type="checkbox" id="remember2" />
                              <span className="tick">
                                <img src={tick} alt="" />
                              </span>
                              <span>Create</span>
                            </label>
                          </div>
                          <div className="select_permission">
                            <label htmlFor="remember3" className="selectCheck">
                              <input type="checkbox" id="remember3" />
                              <span className="tick">
                                <img src={tick} alt="" />
                              </span>
                              <span>Edit</span>
                            </label>
                          </div>
                          <div className="select_permission">
                            <label htmlFor="remember4" className="selectCheck">
                              <input type="checkbox" id="remember4" />
                              <span className="tick">
                                <img src={tick} alt="" />
                              </span>
                              <span>Delete</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3 d-flex justify-content-between align-items-center">
                        <p className="select_permission_label">Services</p>
                        <div className="d-flex align-items-center gap-3 select_permission_group">
                          <div className="select_permission">
                            <label htmlFor="remember1" className="selectCheck">
                              <input type="checkbox" id="remember1" />
                              <span className="tick">
                                <img src={tick} alt="" />
                              </span>
                              <span>Read</span>
                            </label>
                          </div>
                          <div className="select_permission">
                            <label htmlFor="remember2" className="selectCheck">
                              <input type="checkbox" id="remember2" />
                              <span className="tick">
                                <img src={tick} alt="" />
                              </span>
                              <span>Create</span>
                            </label>
                          </div>
                          <div className="select_permission">
                            <label htmlFor="remember3" className="selectCheck">
                              <input type="checkbox" id="remember3" />
                              <span className="tick">
                                <img src={tick} alt="" />
                              </span>
                              <span>Edit</span>
                            </label>
                          </div>
                          <div className="select_permission">
                            <label htmlFor="remember4" className="selectCheck">
                              <input type="checkbox" id="remember4" />
                              <span className="tick">
                                <img src={tick} alt="" />
                              </span>
                              <span>Delete</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubAdminCreate;
