import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const GET_ABOUT_US_REQUEST = 'GET_ABOUT_US_REQUEST';
export const GET_ABOUT_US_SUCCESS = 'GET_ABOUT_US_SUCCESS';
export const GET_ABOUT_US_FAILURE = 'GET_ABOUT_US_FAILURE';

export const GET_CONTACT_US_REQUEST = 'GET_CONTACT_US_REQUEST';
export const GET_CONTACT_US_SUCCESS = 'GET_CONTACT_US_SUCCESS';
export const GET_CONTACT_US_FAILURE = 'GET_CONTACT_US_FAILURE';

export const UPDATE_STATIC_REQUEST = 'UPDATE_STATIC_REQUEST';
export const UPDATE_STATIC_SUCCESS = 'UPDATE_STATIC_SUCCESS';
export const UPDATE_STATIC_FAILURE = 'UPDATE_STATIC_FAILURE';

export const getAboutUsPage = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_ABOUT_US_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/static/about/page/v1`;
            const response = await axios.get(url);
            dispatch({ type: GET_ABOUT_US_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, GET_ABOUT_US_FAILURE, error);
        }
    }
};

export const getContactUsPage = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_CONTACT_US_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/static/contact/page/v1`;
            const response = await axios.get(url);
            dispatch({ type: GET_CONTACT_US_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, GET_CONTACT_US_FAILURE, error);
        }
    }
};

export const updateStaticPage = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_STATIC_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/static/update/page/v1`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_STATIC_SUCCESS, payload: response.data });
            toast("Updated successfully");
        } catch (error) {
            errorHandling(dispatch, UPDATE_STATIC_FAILURE, error);
        }
    }
};