import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAILURE = "PRODUCT_LIST_FAILURE";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAILURE = "PRODUCT_DETAILS_FAILURE";

export const PRODUCT_BRANDS_REQUEST = "PRODUCT_BRANDS_REQUEST";
export const PRODUCT_BRANDS_SUCCESS = "PRODUCT_BRANDS_SUCCESS";
export const PRODUCT_BRANDS_FAILURE = "PRODUCT_BRANDS_FAILURE";

export const PRODUCT_CATEGORIES_REQUEST = "PRODUCT_CATEGORIES_REQUEST";
export const PRODUCT_CATEGORIES_SUCCESS = "PRODUCT_CATEGORIES_SUCCESS";
export const PRODUCT_CATEGORIES_FAILURE = "PRODUCT_CATEGORIES_FAILURE";

export const getProductList = (setIsLoading,region) => {
    return async (dispatch) => {
        try {
            setIsLoading(true);
            dispatch({ type: PRODUCT_LIST_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/admin/get/list/products/v1?regionName=${region}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: PRODUCT_LIST_SUCCESS, payload: response.data });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            errorHandling(dispatch, PRODUCT_LIST_FAILURE, error);
        }
    }
};

export const getProductDetails = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: PRODUCT_DETAILS_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/product/admin/get/single/product/${id}/info/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, PRODUCT_DETAILS_FAILURE, error);
        }
    }
};

export const getProductBrands = (setIsLoading) => {
    return async (dispatch) => {
        try {
            setIsLoading(true);
            dispatch({ type: PRODUCT_BRANDS_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/product/admin/get/all/brand/info/v1?search=""`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: PRODUCT_BRANDS_SUCCESS, payload: response.data });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            errorHandling(dispatch, PRODUCT_BRANDS_FAILURE, error);
        }
    }
};

export const getProductCategories = (setIsLoading) => {
    return async (dispatch) => {
        try {
            setIsLoading(true);
            dispatch({ type: PRODUCT_CATEGORIES_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/product/get/all/product/category/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: PRODUCT_CATEGORIES_SUCCESS, payload: response.data });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            errorHandling(dispatch, PRODUCT_CATEGORIES_FAILURE, error);
        }
    }
};